import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import actStatus from "./actStatus";
import setSidebar from "./setSidebar";
import { USER_LOGOUT } from "../constant/actionTypes";

const reducers = combineReducers({
  setSidebar,
  user,
  auth,
  actStatus,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return reducers(state, action);
};

export default rootReducer;
