import {
  SET_ADMIN_USER,
  SET_BASE_CURRENCY,
  SET_COIN_NETWORK_DATA,
  SET_CRONJOB,
  SET_EXCHANGE_DATA,
  SET_NETWORK_DATA,
  SET_NOTIFICATION,
  SET_ROLL_DATA,
  SET_TARGET_CURRENCY,
  SET_TRADING_PAIR,
  SET_USERS,
  SET_USER_REQUEST,
} from "../constant/actionTypes";

const initial_state = {
  users: [],
  adminUsers: [],
  rollData: [],
  cronJob: [],
  exchanges: [],
  network: [],
  coinNetwork: [],
  baseCurrency: [],
  targetCurrency: [],
  tradingPair: [],
  notifications: [],
  userRequest: [],
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_USERS:
      return { ...state, users: action.data };
    case SET_ADMIN_USER:
      return { ...state, loading: false, adminUsers: action.data };
    case SET_ROLL_DATA:
      return { ...state, loading: false, rollData: action.data };
    case SET_CRONJOB:
      return { ...state, loading: false, cronJob: action.data };
    case SET_EXCHANGE_DATA:
      return { ...state, loading: false, exchanges: action.data };
    case SET_NETWORK_DATA:
      return { ...state, loading: false, network: action.data };
    case SET_COIN_NETWORK_DATA:
      return { ...state, loading: false, coinNetwork: action.data };
    case SET_BASE_CURRENCY:
      return { ...state, loading: false, baseCurrency: action.data };
    case SET_TARGET_CURRENCY:
      return { ...state, loading: false, targetCurrency: action.data };
    case SET_TRADING_PAIR:
      return { ...state, loading: false, tradingPair: action.data };
    case SET_NOTIFICATION:
      return { ...state, loading: false, notifications: action.data };
    case SET_USER_REQUEST:
      return { ...state, loading: false, userRequest: action.data };

    default:
      return { ...state };
  }
};
