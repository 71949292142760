import React, { Component, Suspense, useEffect } from "react";
import {
  BrowserRouter,
  HashRouter,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./scss/style.scss";
import "./scss/style.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setLoginFlag } from "./actions/auth";
import { Modal, notification } from "antd";
import { duration } from "moment";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  // const history = useHistory();
  const loginFlag = useSelector((state) => state?.auth?.loginFlag);
  const dispatch = useDispatch();

  useEffect(() => {
    let timeoutId;
    const axiosInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // history.push("/login");
        // notification["warning"]({
        //   message: "Session Has Expired Please login again",
        // });
        // notification["warning"]({
        //   message: "Session Has Expired. Please login again",
        //   placement: "top",
        // });

        const warning = () => {
          Modal.warning({
            title: "Session Has Expired",
            content: "Tried to Login Again",
          });
        };

        timeoutId = setTimeout(() => {
          Modal.destroyAll();
          dispatch(setLoginFlag(false));
        }, 4000);

        console.error(error);
        if (error.response && error.response.status === 401) {
          // history.push("/login");
          // warning();
          dispatch(setLoginFlag(false));
        }
        return Promise.reject(error);
      }
    );

    return () => {
      clearTimeout(timeoutId);
      axios.interceptors.response.eject(axiosInterceptor);
    };
  }, [history]);

  return (
    <BrowserRouter
      basename="/"
      // hashType="hashbang"
    >
      <Suspense
        fallback={
          <div className="h-screen w-screen flex items-center justify-center">
            Loading ...
          </div>
        }
      >
        <Routes>
          {!loginFlag && (
            <>
              <Route exact path="/" name="Login Page" element={<Login />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
            </>
          )}

          {loginFlag ? (
            <>
              <Route path="*" element={<DefaultLayout />} />
              <Route exact path="*" name="Page 404" element={<Page404 />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/" />} />
          )}

          {/* <Route path="*" name="Home" element={<DefaultLayout />} /> */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
