export const set = "set";

export const SET_LOGIN_FLAG = "SET_LOGIN_FLAG";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_AUTHTOKEN = "SET_USER_AUTHTOKEN";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const USER_LOGOUT = "USER_LOGOUT";

export const SET_USERS = "SET_USERS";
export const SET_ADMIN_USER = "SET_ADMIN_USER";
export const SET_ROLL_DATA = "SET_ROLL_DATA";
export const SET_CRONJOB = "SET_CRONJOB";
export const SET_EXCHANGE_DATA = "SET_EXCHANGE_DATA";
export const SET_NETWORK_DATA = "SET_NETWORK_DATA";
export const SET_COIN_NETWORK_DATA = "SET_COIN_NETWORK_DATA";
export const SET_BASE_CURRENCY = "SET_BASE_CURRENCY";
export const SET_TARGET_CURRENCY = "SET_TARGET_CURRENCY";
export const SET_TRADING_PAIR = "SET_TRADING_PAIR";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_USER_REQUEST = "SET_USER_REQUEST";

export const SET_EXCHANGES = "SET_EXCHANGES";
export const SET_ACTSTATUS = "SET_ACTSTATUS";
export const SET_SUBMIT_STATUS = "SET_SUBMIT_STATUS";
